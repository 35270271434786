import { Injectable } from '@angular/core'
import { addDoc, collection, collectionData, doc, docData, Firestore, getDoc, query, serverTimestamp, setDoc, updateDoc } from '@angular/fire/firestore'
import { Client, clientsPath, FirestoreStructure, LogSearch, logSearchesPath, occupantsPath, paymentMethodsPath } from '@parkupp/core'
import { BehaviorSubject, map, Observable, Subject, Subscription } from 'rxjs'
import { DocumentData } from 'rxfire/firestore/interfaces'
import * as firestore from 'firebase/firestore'
import { Auth } from '@angular/fire/auth'
import { AuthenticationService } from './authentication.service'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    collection = collection(this.firestore, FirestoreStructure.CLIENTS)

    activeClient: Client | null
    clientSubscription: Subscription
    activeClientReady = new Subject()

    private activeClientSubject = new BehaviorSubject<Client | null>(null)
    activeClient$: Observable<Client | null> = this.activeClientSubject.asObservable()

    constructor(public firestore: Firestore, public auth: Auth, private authenticationSvc: AuthenticationService, private router: Router) {
        this.auth.onAuthStateChanged((user) => {
            this.activeClient = null
            if (user?.uid) {
                this.getActiveClient(user?.uid!)
            }
        })
    }

    getDocRef(clientKey: string) {
        return doc(this.firestore, `${FirestoreStructure.CLIENTS}/${clientKey}`)
    }

    adminGetEmailFromClientKey(clientId: string): Observable<any | undefined> {
        const clientPrivateDocRef = doc(this.firestore, `${FirestoreStructure.CLIENTS_PRIVATE}/${clientId}`)
        return docData(clientPrivateDocRef).pipe(
            map((data: any) => {
                return { email: data.email, phoneNumber: data.phoneNumber }
            }) // Assuming 'email' is stored in the private client data
        )
    }

    list(): Observable<Client[]> {
        const clientsQuery = query(this.collection)
        return collectionData(clientsQuery, { idField: '$key' }) as Observable<Client[]>
    }

    get(clientId: string): Observable<any> {
        const clientDocRef = doc(this.firestore, `${FirestoreStructure.CLIENTS}/${clientId}`)
        return docData(clientDocRef, { idField: '$key' })
    }

    getFromEmail(clientId: string): Observable<any> {
        const clientDocRef = doc(this.firestore, `${FirestoreStructure.CLIENTS}/${clientId}`)
        return docData(clientDocRef, { idField: '$key' })
    }

    async getActiveClient(uid: string) {
        if (this.clientSubscription) {
            this.clientSubscription.unsubscribe()
        }
        const documentRef = doc(this.firestore, clientsPath(), uid)
        const documentSnap = await getDoc(documentRef)

        if (documentSnap.exists()) {
            this.activeClient = documentSnap.data() as Client
            this.activeClient.$key = uid

            // TODO: PAR-134 Add email to the client object to improve read speed
            this.activeClient.$email = this.authenticationSvc.email as string
            this.activeClient.$phoneNumber = this.authenticationSvc.phoneNumber as string

            this.activeClientReady.next(this.activeClient! as Client)
            // TODO: This seems to work better
            this.activeClientSubject.next(this.activeClient! as Client)
        }
    }

    createClient(client: Client, uid: string): Promise<any> {
        client.createdAt = serverTimestamp() as firestore.Timestamp
        client.updatedAt = serverTimestamp() as firestore.Timestamp
        const reference = doc(this.firestore, clientsPath(), uid)
        return setDoc(reference, client.parse())
    }

    updateClient(fields: { [id: string]: any }, uid: string): Promise<any> {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        const reference = doc(this.firestore, clientsPath(), uid)
        return updateDoc(reference, fields)
    }

    subscribeToClient(uid: string): Observable<DocumentData> {
        return docData(doc(this.firestore, clientsPath(), uid))
    }

    getAllClientDataOnce(uid: string): Promise<Client> {
        return new Promise<Client>((resolve) => {
            const clientSubscription = docData(doc(this.firestore, clientsPath(), uid)).subscribe((clientDocument: any) => {
                clientSubscription.unsubscribe()

                const client: Client = clientDocument

                const promises = []
                promises.push(
                    new Promise<void>((resolve) => {
                        collectionData(collection(this.firestore, paymentMethodsPath(uid), uid)).subscribe((paymentMethodData: any[]) => {
                            client.$paymentMethods = paymentMethodData
                        })
                    })
                )

                Promise.all(promises).then(() => {
                    resolve(client)
                })
            })
        })
    }

    logClientSearch(googlePlace: LogSearch) {
        googlePlace.createdAt = serverTimestamp() as firestore.Timestamp
        addDoc(collection(this.firestore, logSearchesPath()), googlePlace.parse())
    }

    deleteOccupant(occupantKey: string, clientKey: string) {
        const fieldsToUpdate = {
            deletedAt: serverTimestamp() as firestore.Timestamp,
        }
        updateDoc(doc(this.firestore, occupantsPath(clientKey) + '/' + occupantKey), fieldsToUpdate).then(() => {
            this.router.navigate([`/client/${clientKey}`])
            return
        })
    }
}
